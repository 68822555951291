import * as React from "react";
import { withPreviewResolver } from "gatsby-source-prismic";

const PreviewPage = ({ isPreview, isLoading }) => {
  if (isPreview === false) return "Not a preview!";

  return (
    <>
      <p>Loading</p>
    </>
  );
};

export default withPreviewResolver(PreviewPage, {
  repositoryName: `ted-targett`,
  linkResolver: ({ node, key, value }) => doc => {
    // 	Your link resolver
    if (doc.uid === "home") {
      return `/`;
    } else {
      return `/${doc.uid}`;
    }
  },
});
